import React from "react";
import { Link, injectIntl } from "gatsby-plugin-intl";
import Layout from "../components/Layout";
import Background from "../components/Background";
import Hero from "../components/Hero";
import MotifColumn from "../components/MotifColumn";
import Questions from "../components/Questions";

const NotFoundPage = ({intl}) => (
	<Layout>
		<Background>
			<Hero>
				<MotifColumn>
					<h1>{intl.formatMessage({ id: 'not-found.hero.title'})}</h1>
					<p>{intl.formatMessage({id: 'not-found.hero.text'})}</p>
					<Link to="/">{intl.formatMessage({id: 'not-found.hero.button.text'})}</Link>
				</MotifColumn>
			</Hero>
			<Questions />
		</Background>
	</Layout>
);

export default injectIntl(NotFoundPage);
